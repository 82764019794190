import React from "react"
import { withGiftCard } from "./withGiftCard"
import { Outer, Inner, Section, Title } from "./GiftCardStyles"
import { Description } from "../Description/Description"
import { Image } from "../../Image/Image"
import { GiftCardForm } from "./Form/GiftCardForm"

export const GiftCard = withGiftCard(({ product, product: { title, descriptionHtml }, image, variants }) => (
  <Outer>
    <Inner>
      <Section>
        <Title>{title}</Title>
        <Description description={descriptionHtml} />
        <Image image={image} ratio={"40/27"} />
      </Section>
    </Inner>
    <Inner>
      <Section>
        <GiftCardForm variants={variants} product={product} />
      </Section>
    </Inner>
  </Outer>
))