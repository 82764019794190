import React from "react"

import { withDesktopImages } from "./withDesktopImages"
import { Wrapper } from "./DesktopImagesStyles"
import { Image } from "../../../Image/Image"
import { Video } from "../../../Video/Video"
import { Popup } from "../Popup/Popup"

export const DesktopImages = withDesktopImages(({ media, handleImageClick, popupActive, setPopupActive, clickedSlideIndex }) => (
  <>
    <Wrapper>
      {media.map((item, index) =>
        item?.image?.src ? (
          <Image key={item?.id} image={item?.image} ratio={"8/11"} handleClick={() => handleImageClick(index)} />
        ) : (
          <Video key={item?.id} url={item?.sources?.[2]?.url} image={item?.previewImage} ratio={"8/11"} loop />
        )
      )}
    </Wrapper>
    <Popup active={popupActive} setActive={setPopupActive} media={media} clickedSlideIndex={clickedSlideIndex} />
  </>
))
